import eventBus from '@/utils/eventBus';
import HeaderBlock from './Header';
import FooterBlock from './Footer';
import UI from "@/utils/ui";
export default {
  components: {
    HeaderBlock: HeaderBlock,
    FooterBlock: FooterBlock
  },
  data: function data() {
    return {
      UI: UI,
      inited: false,
      viewMode: localStorage.getItem('VIEWMODE') ? localStorage.getItem('VIEWMODE') : 'light'
    };
  },
  created: function created() {
    var _this = this;

    this.inited = true; // eventBus.$on('afterInit', () => {
    //     this.inited = true
    // })

    eventBus.$on('ChangeViewMode', function (mode) {
      _this.viewMode = mode;
    });
  }
};