
export default {
    // WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    // USDT: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
    // NFT: '0x5cC0215F6491CC18EbBDa6e997cb8A1F41Ef7280',
    // Airdrop: '0xdd19021BdcB6459bB23BCcf072d0b93E82B7bdAe'

//测试网
//    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
//     USDT: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
    NFT: '0xAC1f14fa6F41B18327484b3aeA2B1eF4164c1F65',
    Airdrop: '0xC2e9ed4b408E9716f374D0b912d9Aa1Fb0D54905',
    Task:'0x83a5B7Db3311B10407B5d23D4fdB1767A0BBa72E',
      WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
     USDT: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
 //  NFT: '0x21B50A2F4390741A897c5ac63897A7306a88c3eF',
 // Airdrop: '0x2338A92b4E428957178EC7544dF93DdB3a7cE473',
 //   TaskPet: '0xA08A470eAA313A8521a4eB518619D3cd6fFa39B1'
    TaskPetBNB:'0xaB1d03c92F02Bd10ac55646ea1aC01c4105386c3',
    TaskPet:'0xaB1d03c92F02Bd10ac55646ea1aC01c4105386c3',
    TaskPetZKS:'0xA6303E7e53f007C47B1a3Da9Ef5F5131BDb9b2dF',
    TaskPetBASE:'0xFBf3d691f91c1FFEA47A2704919a291d92F2eD9b',


}
