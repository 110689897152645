/** 语言包key值请参考vant3，网址：https://vant-contrib.gitee.io/vant/#/zh-CN/locale **/
export default {
    'zh-CN': {
        'copy-success': '复制成功',
        'title': 'NFT市场',
        'nft.name': 'ZK dragon',
        'nft.desc': '',
        'nft.recommend': '推荐',
        'nft.language': '语言切换',
        'nft.language1': '选择语言',
        'task.task': '任务',
        'task.taskType': '任务类型',
        'task.taskStatusName': '任务状态',
        'task.title': '任务标题',
        'task.network': '公链类型',
        'task.expiration': '过期时间',
        'task.count': '发布件数',
        'task.numWinner': '中奖数量',
        'task.content': '任务内容',
        'task.price': '单价',
        'task.mount': '总价',
        'task.create': '创建任务',
        'task.peritem': '每件结算',
        'task.lottery': '抽奖',
        'task.taskCount': '任务数',
        'task.amount': '任务总金额',
        'task.submit': '提交数目',
        'task.approveName': '任务状态',
        'task.submitToAdmin': '提交给管理员',
        'task.adminapproving': '管理员审核中',
        'task.complete': '完成数',
        'task.available': '可用数',
        'task.creater': '创建者',
        'task.endTime': '到期时间',
        'task.viewTask': '查看任务',
        'task.manuscript': '稿件列表',
        'task.adminapprovepass': '管理员审核通过',
        'task.adminreject': '管理员驳回',
        'task.adminpass': '审核通过',
        'task.reject': '驳回',
        'task.manuscriptonly': '稿件仅任务审核者可见',
        'task.content1': '1 本平台为去中心化任务平台，交易过程通过智能合约确认，资金交易都在链上，交易不可逆，请仔细核对操作 2 输入你完成任务的凭证，比如账号名、tx、截图等等! 3 获取佣金:发布者审核通过、稿件提交超过24小时未操作默认通过审核、发生争议上报管理员通过审核.3 完成任务后，任务发布者和完成任务者都能获得同样的积分奖励，1$=1分，我们将会在未来按照积分空投$task. 4 提交以后，保持当前页面，请不要操作，等待区块链返回数据。',
        'task.my': '我的',
        'task.evidence': '完成证据',
        'task.evidence1': '输入任务相关可供核实的信息',
        'my.username': '账号',
        'my.nickName': '昵称',
        'my.xname': 'x账号',
        'my.contactInfo': '联系方式',
        'my.score': '积分',
        'my.invite': '邀请',
        'my.inviteContent': '邀请好友注册，好友mint nft激活账号，可以立即获得2分。',
        'post.title': '标题',
        'post.create': '发布',
        'community.title': '社区名',
        'community.search': '搜索',


        'community.community': '社区',
        'community.my': '我的',
        'community.communityowner': '社区长',
        'community.people': '人',
        'community.communitypeople': '社区人数',
        'community.joinprice': '加入价格',
        'community.createTime': '创建时间',
        'community.joincommunity': '加入社区',
        'community.moreaction': '更多操作',
        'community.quitecommunity': '退出社区',
        'community.updatecommunity': '更新社区',
        'community.viewcommunity': '查看社区',

        'community.communitytitle': '社区名称',
        'community.communitychat': '社区群聊',
        'community.joinafter': '加入社区后可见',
        'community.notice': '公告',
        'community.communitydetail': '社区介绍',

    },
    'en-US': {
        'copy-success': 'Copy successful!',
        'title': 'NFT Market',
        'nft.name': 'ZK dragon',
        'nft.desc': ' ',
        'nft.recommend': '',
        'nft.language': 'language',
        'nft.language1': 'choose language',
        'task.title': 'title',
        'task.network': 'network',
        'task.taskStatusName': 'taskStatus',
        'task.taskType': 'taskType',
        'task.expiration': 'expiration time',
        'task.count': 'count',
        'task.numWinner': 'num winner',
        'task.content': 'content',
        'task.price': 'price',
        'task.mount': 'mount',
        'task.create': 'create',
        'task.peritem': 'peritem',
        'task.adminapproving': 'admin approving',
        'task.manuscript': 'Evidence List',
        'task.manuscriptonly': 'Only visible to task reviewers',
        'task.adminapprovepass': 'adminapprovepass',
        'task.adminreject': 'adminreject',
        'task.adminpass': 'adminpass',
        'task.reject': 'reject',
        'task.lottery': 'lottery',
        'task.taskCount': 'taskCount',
        'task.approveName': 'task status',
        'task.submitToAdmin': 'task status',
        'task.amount': 'amount',
        'task.submit': 'submit',
        'task.complete': 'complete',
        'task.available': 'available',
        'task.endTime': 'endTime',
        'task.viewTask': 'viewTask',
        'task.creater': 'creater',
        'task.evidence1': 'input evidence such as you name!',
        'task.my': 'my',
        'task.evidence': 'evidence',
        'task.content1': '1. This platform is a decentralized task platform, and the transaction process is confirmed through smart contracts. All fund transactions are on the chain, and transactions are irreversible. Please carefully check the operation. 2. Enter your credentials for completing the task, such as account name, tx, screenshot, and so on! 3. Commission Obtained: Reviewed by the publisher, if the manuscript is submitted for more than 24 hours without any action, it will be deemed approved by default, and any disputes will be reported to the administrator for approval。3 After completing the task, both the task publisher and the task executor will receive the same point reward. 1 $=1 point, and we will air drop $tasks based on the points in the future. 4 After submitting, please keep the current page and do not operate. Wait for the blockchain to return the data.',

        'my.username': 'username',
        'my.nickName': 'nickName',
        'my.xname': 'x account',
        'my.contactInfo': 'contactInfo',
        'my.score': 'score',
        'my.invite': 'invite',
        'my.inviteContent': '邀请好友注册，好友mint nft激活账号，你可以立即获得2分。',
        'post.title': '标题',
        'post.create': '发布',
        'community.title': 'community name',

        'community.search': 'search',
        'community.community': 'search',
        'community.my': 'my',
        'community.communityowner': 'communityowner',
        'community.people': 'people',
        'community.communitypeople': 'community people',
        'community.joinprice': 'join price',
        'community.createTime': 'createTime',
        'community.joincommunity': 'joincommunity',
        'community.moreaction': 'moreaction',
        'community.quitecommunity': 'quit',
        'community.updatecommunity': 'update',
        'community.viewcommunity': 'view',
        'community.communitytitle': 'community title',
        'community.communitychat': 'community chat',
        'community.joinafter': 'after join can see the message',
        'community.notice': 'notice',
        'community.communitydetail': 'community detail',
    },
    // ...
}
