import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConfig from "../config/wallet";
import {getCurrentInstance, ref, toRaw} from "vue";
import store from '../store'
import Contract from './contract'
import CommonFunc from "@/utils/commonFunction";
import detectEthereumProvider from "@metamask/detect-provider";
import {request} from "@/utils/request";
import mitt from "@/utils/mitt";
async function connect(flag,chainId,isadd){

    const networkConfig = await CommonFunc.swithNetwork(chainId)


    const providerOptions = WalletConfig.providerOptions;

    for(let i in providerOptions){
        if(!providerOptions[i].options){
            continue
        }

        console.log(providerOptions)
        providerOptions[i].options.chainId = networkConfig.chainId
        if(networkConfig.rpcs){
            providerOptions[i].options.rpc = networkConfig.rpcs
        } else {
            const _rpcs = {}
            _rpcs[networkConfig.chainId] = networkConfig.rpcUrl
            providerOptions[i].options.rpc = _rpcs
        }
    }

    let provider, web3Modal
    try {
        web3Modal = new Web3Modal({
            network: networkConfig.network,
            cacheProvider: true,
            disableInjectedProvider: false, // disable metamask
            providerOptions
        })

        if(web3Modal && !flag){
            setTimeout(() => {
                if(!provider){
                    console.log('ClearCachedProvider and reconnect')
                    web3Modal.clearCachedProvider()
                    connect(false,chainId)
                }
            }, 3000)
        }
        provider = await web3Modal.connect()

    } catch (err){

        web3Modal.clearCachedProvider()
    }

    if(provider){

        window.provider = provider
        window.web3 = provider.type == 'TRON' ? provider.web3 : new Web3(provider)
        if(window.web3.trx){
            provider.selectedAddress = window.web3.defaultAddress.base58
        } else {


            const chainId1 = await web3.eth.getChainId()
            const chainId2 = networkConfig.chainId

            if(chainId1 != networkConfig.chainId){

                if(isadd==true){
                    await _addChain(provider, networkConfig)
                }else{
                    const networkConfig1 = await CommonFunc.swithNetwork(chainId1)
                    web3Modal = new Web3Modal({
                        network: networkConfig1.network,
                        cacheProvider: true,
                        disableInjectedProvider: false, // disable metamask
                        providerOptions
                    })
                    provider = await web3Modal.connect()

                    sessionStorage.setItem("chainId",chainId1);
                    mitt.emit("currentChain", chainId1);

                }

            }



            if(provider.accounts && provider.selectedAddress != provider.accounts[0]){
                provider.selectedAddress = provider.accounts[0]
            }
        }

        window.connectedAddress = provider.selectedAddress
        window.web3Modal = web3Modal

        store.dispatch('setCurrentAccount', window.connectedAddress)

        getCurrentInstance()?.proxy.$eventBus.$emit('WALLET_CONNECTED', window.connectedAddress)

        subscribeProvider(window.provider)

        // Init Contract
        window.connectedAddress && Contract.init()
        if(isadd==true) {
            sessionStorage.setItem("chainId", chainId);
            mitt.emit("currentChain", chainId);

        }
        // let val = window.web3.utils.sha3('sss')//将数据消息哈希
        // window.web3.eth.sign(val, provider.selectedAddress, (err, sign) => {
        //     if (err) { //签名失败
        //         console.error(err);
        //     } else { //签名成功
        //         console.log(sign);//返回的签名
        //
        //         debugger;
        //     }
        // })
        // eth.personal.sign


        var uu=sessionStorage.getItem("longUser");
        var nota=1;
        if(uu!=null) {
            var uu1 = JSON.parse(uu);
            var uu2 =uu1.userAccount;
            if(provider.selectedAddress!=null){
                if(uu2!=provider.selectedAddress){
                    nota=2;
                }
            }


        }
        if(uu==null||nota==2){

        window.web3.eth.personal.sign('login', provider.selectedAddress, (err, sign) => {
            if (err) {
                console.error(err);
            } else {
                console.log(sign);
                window.web3.eth.personal.ecRecover('login', sign).then(res => {
                    const inviteCode= sessionStorage.getItem('inviteCode');
                    sessionStorage.setItem('inviteCode',"");
                    console.log(res)//获得该签名者的地址
                    const teamAvatarUrl =  request.post("/user/web3login", {
                        'sign': sign,
                        'address': provider.selectedAddress,
                        'inviteId':inviteCode
                    }, {
                        headers: {'Content-Type': 'multipart/form-data'},
                    }).then(res => {

                        debugger;
                        if(res.code==0){
                            var user=res.data;
                            sessionStorage.setItem("longUser", user ? JSON.stringify(user) : JSON.stringify({}))
                            localStorage.setItem('userInfor', JSON.stringify(user))

                        }

                    })




                })
            }
        })

        }



    }
}
 function getCid(flag,chainId){


    const networkConfig =  CommonFunc.swithNetwork(chainId)


    const providerOptions = WalletConfig.providerOptions;

    for(let i in providerOptions){
        if(!providerOptions[i].options){
            continue
        }

        console.log(providerOptions)
        providerOptions[i].options.chainId = networkConfig.chainId
        if(networkConfig.rpcs){
            providerOptions[i].options.rpc = networkConfig.rpcs
        } else {
            const _rpcs = {}
            _rpcs[networkConfig.chainId] = networkConfig.rpcUrl
            providerOptions[i].options.rpc = _rpcs
        }
    }

    let provider, web3Modal
    try {
        web3Modal = new Web3Modal({
            network: networkConfig.network,
            cacheProvider: true,
            disableInjectedProvider: false, // disable metamask
            providerOptions
        })

        // if(web3Modal && !flag){
        //     setTimeout(() => {
        //         if(!provider){
        //             console.log('ClearCachedProvider and reconnect')
        //             web3Modal.clearCachedProvider()
        //             connect(false,chainId)
        //         }
        //     }, 3000)
        // }
        provider =  web3Modal.connect()

    } catch (err){

        web3Modal.clearCachedProvider()
    }

    if(provider){

        window.provider = provider
        window.web3 = provider.type == 'TRON' ? provider.web3 : new Web3(provider)
        if(window.web3.trx){
            provider.selectedAddress = window.web3.defaultAddress.base58
        } else {


            const chainId1 =  web3.eth.getChainId()

             return chainId1;
        }

    }
}
async function _addChain(provider, networkConfig){

    let res = await provider.request({
        method: 'eth_requestAccounts'
    })
    if(!res || res.length == 0){
        return false
    }

    console.log(networkConfig)

    await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
            {
                chainId: web3.utils.numberToHex(networkConfig.chainId),
                chainName: networkConfig.chainName,
                nativeCurrency: {
                    name: networkConfig.chainSymbol,
                    symbol: networkConfig.chainSymbol,
                    decimals: networkConfig.decimals
                },
                //rpcUrls: networkConfig.rpcUrl.split(','),
                rpcUrls: networkConfig.rpcUrl,
                blockExplorerUrls: networkConfig.scanUrl
            }
        ]
    })
}


async function connect1(flag){

    const networkConfig = await CommonFunc.getCurrentNetwork()


    const providerOptions = WalletConfig.providerOptions;

    for(let i in providerOptions){
        if(!providerOptions[i].options){
            continue
        }

        console.log(providerOptions)
        providerOptions[i].options.chainId = networkConfig.chainId
        if(networkConfig.rpcs){
            providerOptions[i].options.rpc = networkConfig.rpcs
        } else {
            const _rpcs = {}
            _rpcs[networkConfig.chainId] = networkConfig.rpcUrl
            providerOptions[i].options.rpc = _rpcs
        }
    }

    let provider, web3Modal
    try {
        web3Modal = new Web3Modal({
            network: networkConfig.network,
            cacheProvider: true,
            disableInjectedProvider: false, // disable metamask
            providerOptions
        })

        if(web3Modal && !flag){
            setTimeout(() => {
                if(!provider){
                    console.log('ClearCachedProvider and reconnect')
                    web3Modal.clearCachedProvider()
                    connect(true)
                }
            }, 3000)
        }
        provider = await web3Modal.connect()

    } catch (err){

        web3Modal.clearCachedProvider()
    }

    if(provider){

        window.provider = provider
        window.web3 = provider.type == 'TRON' ? provider.web3 : new Web3(provider)
        if(window.web3.trx){
            provider.selectedAddress = window.web3.defaultAddress.base58
        } else {

            const chainId = await web3.eth.getChainId()
            if(chainId != networkConfig.chainId){

                await _addChain(provider, networkConfig)
            }

            if(provider.accounts && provider.selectedAddress != provider.accounts[0]){
                provider.selectedAddress = provider.accounts[0]
            }
        }

        window.connectedAddress = provider.selectedAddress
        window.web3Modal = web3Modal

        store.dispatch('setCurrentAccount', window.connectedAddress)

        getCurrentInstance()?.proxy.$eventBus.$emit('WALLET_CONNECTED', window.connectedAddress)

        subscribeProvider(window.provider)

        // Init Contract
        window.connectedAddress && Contract.init()

    }
}
async function disconnect() {

    if (window.provider == null) {
        return
    }
    if(typeof(window.provider.disconnect) == 'function'){
        await window.provider.disconnect()
    }
    await window.web3Modal.clearCachedProvider()
    window.provider = null
    window.connectedAddress = null
    window.web3Modal = null
    sessionStorage.removeItem("longUser")
    store.dispatch('setCurrentAccount', window.connectedAddress)
    getCurrentInstance()?.proxy.$eventBus.emit('WALLET_DISCONNECTED')
}

function subscribeProvider(provider){
    if (!provider.on) {
        return
    }
    provider.on("close", () => {
        disconnect()
    })
    provider.on("chainChanged", () => {
        console.log('chainChanged')
    })
    provider.on("networkChanged", () => {
        console.log('networkChanged')
    })
    provider.on("accountsChanged", () => {
        console.log('accountsChanged')
        window.location.reload()
    })
}

export default {
    connect,
    disconnect,
    getCid
}
