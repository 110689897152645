import { createRouter, createWebHashHistory,createWebHistory} from "vue-router"
import Container from '../views/layout/Container'

const routes = [{
    path: "/",
    name: "Home",
    redirect: '/home',
    component: Container,
    children: [
        {
            path: '/docs',
            name: 'docs',
            component: () => import('../views/Docs.vue'),
            meta: { title: 'docs', keepAlive: true }
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('../views/Index.vue'),
            meta: { title: 'home', keepAlive: true }
        },
        {
            path: '/task',
            name: 'task',
            component: () => import('../views/task.vue'),
            meta: { title: 'mintnft', keepAlive: true }
        },
        {
            path: '/addpost',
            name: 'addpost',
            component: () => import('../views/post/addpost.vue'),
            meta: { title: 'addpost', keepAlive: true }
        },
        {
            path: '/post',
            name: 'post',
            component: () => import('../views/post/index.vue'),
            meta: { title: 'post', keepAlive: true }
        },
        {
            path: '/community',
            name: 'community',
            component: () => import('../views/community/index.vue'),
            meta: { title: 'community', keepAlive: true }
        },
        {
            path: '/communityShow',
            name: 'communityShow',
            component: () => import('../views/community/communityShow.vue'),
            meta: { title: 'communityShow', keepAlive: true }
        },
        {
            path: '/addcommunity',
            name: 'addcommunity',
            component: () => import('../views/community/addcommunity.vue'),
            meta: { title: 'addcommunity', keepAlive: true }
        },
        {
            path: '/community/edit',
            name: 'communityedit',
            component: () => import('../views/community/communityEdit.vue'),
            meta: { title: 'communityedit', keepAlive: true }
        },
        {
            path: '/detail',
            name: 'postdetail',
            component: () => import('../views/post/detail.vue'),
            meta: { title: 'postdetail', keepAlive: true }
        },
        {
            path: '/task/detail',
            name: 'detail',
            component: () => import('../views/task/TaskDetail.vue'),
            meta: { title: 'detail', keepAlive: true }
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('../views/user.vue'),
            meta: { title: 'user', keepAlive: true }
        },
        {
            path: '/task/create',
            name: 'createtask',
            component: () => import('../views/task/TaskCreatePage.vue'),
            meta: { title: 'createtask', keepAlive: true }
        },
        {
            path: '/withdraw',
            name: 'withdraw',
            component: () => import('../views/withdraw.vue'),
            meta: { title: 'withdraw', keepAlive: true }
        },
        {
            path: '/public_chat',
            name: 'public_chat',
            component: () => import('../views/chat/public_chat.vue'),
            meta: { title: 'home', keepAlive: true }
        },
        {
            path: '/chat',
            name: 'chat',
            component: () => import('../views/chat/ChatPage.vue'),
            meta: { title: 'chat', keepAlive: true }
        },
        {
            path: '/group_chat',
            name: 'group_chat',
            component: () => import('../views/chat/group_chat.vue'),
            meta: { title: 'home', keepAlive: true }
        },
        {
            path: '/my',
            name: 'my',
            component: () => import('../views/my.vue'),
            meta: { title: 'my', keepAlive: true }
        },
        {
            path: '/mintnft',
            name: 'mintnft',
            component: () => import('../views/mintnft.vue'),
            meta: { title: 'my', keepAlive: true }
        },
        {
            path: '/user/edit',
            name: 'edit',
            component: () => import('../views/UserEdit.vue'),
            meta: { title: 'edit', keepAlive: true }
        },
        {
            path: '/user/invite',
            name: 'invite',
            component: () => import('../views/invite.vue'),
            meta: { title: 'invite', keepAlive: true }
        },
        {
            path: '/user/scorehistory',
            name: 'scorehistory',
            component: () => import('../views/scorehistory.vue'),
            meta: { title: 'scorehistory', keepAlive: true }
        },
        {
            path: '/messagewall',
            name: 'wall',
            component: () => import('../views/messagewall.vue'),
            meta: { title: 'messagewall', keepAlive: true }
        },
        {
            path: '/messagewall/detail',
            name: 'messagewalldetail',
            component: () => import('../views/messagewallDetail.vue'),
            meta: { title: 'messagewalldetail', keepAlive: true }
        },
        {
            path: '/nftdetail',
            name: 'nftdetail',
            component: () => import('../views/nft/details.vue'),
            meta: { title: 'nftdetail', keepAlive: true }
        }
        ]
}]

const router = createRouter({
    mode:"history",
    history: createWebHistory(),
    routes: routes
})
export default router
