import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eb1099dc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "footer-con"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, function (item, idx) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["tab-item", {
        on: _this.active == idx
      }]),
      key: idx,
      onClick: function onClick($event) {
        return $options.onChange(idx);
      }
    }, [_createElementVNode("img", {
      src: _this.active == idx ? item.active : item.inactive
    }, null, 8, _hoisted_3), _createElementVNode("span", null, _toDisplayString(item.name), 1)], 10, _hoisted_2);
  }), 128))]);
}