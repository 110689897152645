import { createApp } from 'vue'
import App from './App.vue'

import "vant/lib/index.css";
import "amfe-flexible";
import { QuillEditor } from '@vueup/vue-quill'

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.core.css';

import {
    Button,
    Field,
    CellGroup,
    Tabbar,
    TabbarItem,
    Search,
    Grid,
    GridItem,
    List,
    Swipe,
    SwipeItem,
    Overlay,
    Dialog,
    Icon,
    Divider,
    Tag,
    Popup,
    Toast,
    Uploader,
    Picker,
    Image as VanImage,
    ImagePreview,
    Pagination,
    DropdownMenu, DropdownItem,Locale,
} from "vant";

const app = createApp(App)


// 3.注册使用组件
app.use(Toast);
app.use(Button);
app.use(Field);
app.use(CellGroup);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Search);
app.use(Grid);
app.use(GridItem);
app.use(List);
app.use(Swipe);
app.use(SwipeItem);
app.use(Overlay);
app.use(Dialog);
app.use(Icon);
app.use(Divider);
app.use(Tag);
app.use(Popup);
app.use(Uploader);
app.use(Picker);
app.use(VanImage);
app.use(ImagePreview);
app.use(Pagination);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Locale);
// event bus
import eventBus from './utils/eventBus'
app.config.globalProperties.$eventBus = eventBus
// store
import store from './store'
app.use(store)
// router
import router from './router'
app.use(router)

// i18n
import i18n from './utils/language'



app.use(i18n)
window.t = i18n.global.t

app.component('QuillEditor', QuillEditor).mount('#app')
